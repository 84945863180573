<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div class="table-header"></div>
      <h3 style="margin-top: 30pt">
        {{ $t(`mCompany.mylink.linkUser`) }}
      </h3>
      <ul class="list-group">
        <li class="list-group-item">
          <span style="line-height: 30pt; cursor: pointer" id="linkuser" @click="copyUrl('linkuser')">{{ servidor }}{{ id_emp }}/student/login</span>          
          <button class="btn button buttonEdit" :style="{ backgroundColor: colorCompany }" @click="copyUrl('linkuser')"><i class="fas fa-copy"></i></button>
        </li>
        <li class="list-group-item">
          <span style="line-height: 30pt; cursor: pointer" id="linkprofesor" @click="copyUrl('linkprofesor')">{{ servidor }}profesor/login</span>          
          <button class="btn button buttonEdit" :style="{ backgroundColor: colorCompany }" @click="copyUrl('linkprofesor')"><i class="fas fa-copy"></i></button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MenuVer from "../../../components/MenuVer";
import axios from "axios";

export default {
  data() {
    return {
      fonts: [
        {
          fuen_nombre: "APP",
          fuen_id: 0,
        },
      ],
    };
  },
  components: {
    MenuVer,
  },
  created() {
    this.changeBreadCrumb(12);
    this.bigDrop();
    this.getFonts();
  },
  computed: {
    ...mapState(["id_emp","colorCompany","servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getFonts() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=fuente&ac=listado`
        );
        if (res.data) {
          this.fonts = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    copyUrl(nombre) {
      const codigoACopiar = document.getElementById(nombre);
      let seleccion = document.createRange();
      seleccion.selectNodeContents(codigoACopiar);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(seleccion);
      document.execCommand("copy");
      window.getSelection().removeRange(seleccion);
    },
  },
};
</script>

<style scoped>
</style>